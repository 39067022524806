.markdown-container > table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}

.markdown-container th,
.markdown-container td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.markdown-container th {
  background-color: #f2f2f2;
}

.markdown-container tbody tr:hover {
  background-color: #f5f5f5;
}

.markdown-container p {
  font-size: 16px;
  /* font-family: "Urbanist", "sans-serif"; */
  font-weight: 400;
  text-align: start;
  padding: 4px;
  display: block;
  margin-bottom: 10px;
}

.quizContainer p {
  font-size: 14px;
  font-weight: 600;
  font-family: "Urbanist", "sans-serif";
  text-align: start;
}

.quizOptionContainer p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Urbanist", "sans-serif";
  text-align: start;
  margin-bottom: 5px;
}

.markdown-container code {
  /* display: block; */
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}
