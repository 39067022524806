@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Inter:wght@400;500;600;700&family=Manrope:wght@400;500;600;700&family=Montserrat:wght@400;500;600&family=Poppins:wght@400;500;600;700&family=Urbanist:wght@400;500;600;700;800&family=Mulish:wght@400;500;600&family=Raleway:wght@700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* copied carousel settings  */
/** slick custom style */

.ql-toolbar {
  border: 1px solid #e2e8f0 !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ql-container {
  border: 1px solid #e2e8f0 !important;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.markdown-editor .react-mde {
  border-radius: 8px;
}

.markdown-editor .mde-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.markdown-editor .mde-header .mde-tabs .selected {
  padding: 5px 10px;
  border-radius: 10px;
}

.markdown-editor .mde-textarea-wrapper {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

@media screen and (prefers-color-scheme: dark) {
  .dark-mode .ql-toolbar {
    border: 1px solid #45007c !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .dark-mode .ql-container {
    border: 1px solid #45007c !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .dark-mode .react-mde {
    border: 1px solid #45007c !important;
  }
}

.slick-track {
  display: flex !important;
}
.slick-slider {
  height: 100% !important;
  min-height: 300px !important;
}

@layer components {
  .tableHeaderText {
    @apply font-urbanist text-sm font-semibold text-black dark:text-white text-center border-r border-r-gray-200;
  }

  .courseAddButtonStyle {
    @apply w-max my-3 border border-purple6 rounded-md px-6 py-2 font-urbanist font-medium text-sm text-purple6;
  }

  .courseHeadingStyle {
    @apply font-urbanist font-semibold text-black dark:text-white text-sm block mb-1;
  }

  .addCourseNameInputStyle {
    @apply max-w-lg md:max-w-full dark:bg-purple14 dark:border-purple26 w-full outline-none font-urbanist font-semibold text-black dark:text-white text-sm px-4 py-1 rounded-lg border mb-2;
  }

  .addBlogInputStyle {
    @apply w-full rounded-md border bg-gray49 dark:bg-purple24 border-gray48 dark:border-purple25 text-gray40 placeholder:text-gray40 dark:placeholder:text-gray-400 dark:text-gray-200 outline-none px-4 py-1 text-sm;
  }
  .addBlogText {
    @apply font-urbanist font-medium text-black1 dark:text-white text-sm;
  }
}

@layer base {
}
