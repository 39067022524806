.blog_description > h1 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
}

.blog_description > h2 {
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
}

.blog_description > h3 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
}

.blog_description > h4 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
}

.blog_description > p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
}

.blog_description > span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
}