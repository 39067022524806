.container::-webkit-scrollbar {
  width: 14px;
  border-radius: 20px;
}

.container::-webkit-scrollbar-track {
  background-color: #e8e8e8; /* Adjust the background color as desired */
  border-radius: 15px; /* Adjust the border radius as desired */
}
::-webkit-scrollbar-thumb {
  width: 24px;
  background-color: #4c6feb; /* Adjust the color as desired */
  border-radius: 15px; /* Adjust the border radius as desired */
  border: 5px solid #e8e8e8;
}
